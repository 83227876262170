/** @jsx jsx */
import { jsx } from 'theme-ui'
// import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer sx={{ bg: 'gray.100', p: 3, textAlign: 'center' }}>
      <h2 sx={{ fontFamily: 'heading', fontSize: '11px' }}>
        more. most.
        {
          // <Link
          //   to="/that"
          //   sx={{
          //     ':hover': {
          //       textDecoration: 'underline',
          //     },
          //   }}
          // >
        }
        &nbsp;that
        {
          // </Link>
        }
        .
      </h2>
    </footer>
  )
}

export default Footer
