/** @jsx jsx */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { jsx } from 'theme-ui'

import Header from './header'
import Footer from './footer'
import '../styles/fonts.css'
import './global.css'
import './layout.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header siteTitle={data.site.siteMetadata.title} sx={{ flex: 'none' }} />
      <main
        sx={{
          flexGrow: 1,
          width: '100%',
          maxWidth: 1440,
          mx: 'auto',
          py: [2, 3],
          px: [3, 4],
        }}
      >
        {children}
      </main>
      <Footer sx={{ flex: 'none' }} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
