/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'

const Header = () => (
  <header
    sx={{
      bg: 'gray.100',
      position: 'sticky',
      top: 0,
      zIndex: 9999,
    }}
  >
    <div
      sx={{
        display: 'flex',
        alignItems: ['flex-start', 'flex-start', 'center'],
        flexDirection: ['column', 'column', 'row'],
        m: '0 auto',
        maxWidth: '1440px',
        py: 3,
        px: [0, 4],
      }}
    >
      <nav
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          gridTemplateRows: ['auto auto', 'auto auto', 'auto'],
          gridTemplateAreas: [
            `
            'logo . rsvp'
            'list list list'
            `,
            `
            'logo . rsvp'
            'list list list'
            `,
            `'logo list rsvp'`,
          ],
          alignItems: 'center',
        }}
      >
        <h1
          sx={{
            gridArea: 'logo',
            m: 0,
            variant: 'text.heading',
            textTransform: 'none',
            ml: [3, 0],
            fontSize: 3,
          }}
        >
          <Link
            to="/"
            sx={{
              position: 'relative',
              color: 'text',
              textDecoration: 'none',
              textShadow: `
                1px 0 var(--theme-ui-colors-gray-100,#f5f5f5), 
                2px 0 var(--theme-ui-colors-gray-100,#f5f5f5), 
                -1px 0 var(--theme-ui-colors-gray-100,#f5f5f5), 
                -2px 0 var(--theme-ui-colors-gray-100,#f5f5f5)
              `,
              ':hover, :focus': {
                '.estly': {
                  color: 'primary',
                },
                '::after': {
                  transform: 'scaleX(3.51)',
                },
              },
              '::after': {
                content: '""',
                position: 'absolute',
                zIndex: '-1',
                bottom: '1px',
                left: 0,
                height: '1px',
                width: '46px',
                backgroundColor: 'primary',
                transition: 'transform 200ms ease-in-out',
                transformOrigin: 'center left',
              },
            }}
          >
            <span sx={{ color: 'primary' }}>hahn</span>
            <span className="estly" sx={{ whiteSpace: 'nowrap' }}>
              estly in love.
            </span>
          </Link>
        </h1>
        <div
          sx={{
            gridArea: 'list',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            fontSize: 1,
            textTransform: 'uppercase',
            mt: [3, 3, 0],
            ml: [0, 0, 'auto'],
            mr: [0, 0, 4],
            '> * + *': {
              ml: [0, '1.5rem', 4],
            },
            '> *': {
              ':first-of-type': {
                pl: [3, 0],
              },
              ':last-of-type': {
                pr: [3, 0],
              },
              py: 1,
              px: ['.75rem', 0],
            },
            ' [aria-current] > span::after': {
              transform: 'scaleX(1)',
            },
          }}
        >
          <Link
            to="/wedding"
            sx={{
              variant: 'buttons.text',
              fontWeight: 300,
              letterSpacing: '.075em',
              '> span::before': {
                height: 0,
              },
            }}
          >
            <span>Wedding</span>
          </Link>
          <Link
            to="/engagement"
            sx={{
              variant: 'buttons.text',
              fontWeight: 300,
              letterSpacing: '.075em',
              '> span::before': {
                height: 0,
              },
            }}
          >
            <span>Engagement</span>
          </Link>
          <Link
            to="/registry"
            sx={{
              variant: 'buttons.text',
              fontWeight: 300,
              letterSpacing: '.075em',
              '> span::before': {
                height: 0,
              },
            }}
          >
            <span>Registry</span>
          </Link>
        </div>
      </nav>
    </div>
  </header>
)

export default Header
